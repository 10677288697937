import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../context';

const ButtonGroup = () => {
  const [{ theme }] = useStore();
  const { t } = useTranslation('managedHostingOverigeDiensten');

  return useMemo(
    () => (
      <p className="btn-group">
        <a className="link-button" href={`${process.env.GATSBY_PORTAL_URL}/bestellen?m=${theme}`}>
          <span>{t('domains')}</span>
        </a>
        <a
          className="link-button"
          href={`${process.env.GATSBY_PORTAL_URL}/bestellen/ssl-certificaten?m=${theme}`}
        >
          <span>{t('certificates')}</span>
        </a>
      </p>
    ),
    [t, theme],
  );
};

export default ButtonGroup;
