import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Link from '../../../i18n/Link';
import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';
import GetReady from '../../../components/Parts/GetReady';
import Image from '../../../components/Elements/Image';
import Row, { Col } from '../../../components/Elements/Grid';

import ButtonGroup from '../../../components/Elements/ButtonGroup';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('managedHostingOverigeDiensten');

  return (
    <Layout className="overige-diensten">
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerOverigeDiensten"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center bottom' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('title')}</h1>
            <Row>
              <Col>
                <p>
                  <Trans t={t} i18nKey="intro">
                    <Link to="/contact" />
                  </Trans>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="text-center">{t('order')}</h3>
                <ButtonGroup />
              </Col>
            </Row>
          </PageSection>
          <PageSection grid slide>
            <Row>
              <Col>
                <h5>{t('domainNames.title')}</h5>
                <p>{t('domainNames.description')}</p>
              </Col>
              <Col>
                <h5>{t('sslCertificates.title')}</h5>
                <p>{t('sslCertificates.description')}</p>
              </Col>
              <Col>
                <h5>{t('loadbalancing.title')}</h5>
                <p>{t('loadbalancing.description')}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>{t('vpn.title')}</h5>
                <p>{t('vpn.description')}</p>
              </Col>
              <Col>
                <h5>{t('logging.title')}</h5>
                <p>{t('logging.description')}</p>
              </Col>
              <Col>
                <h5>{t('containers.title')}</h5>
                <p>{t('containers.description')}</p>
              </Col>
            </Row>
          </PageSection>
          <GetReady title={t('getSalesQuote')} />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
